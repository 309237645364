import { Stack, Typography, styled, useTheme } from '@mui/material';
import { memo } from 'react';
import MainPhoneGeneralMeeting from '../../../assets/general-meeting-description.png';

interface GeneralMeetingDescriptionProps {
  title: string;
  description: string;
}

const GeneralMeetingDescriptionImage = styled('img')({
  width: '386px',
  '@media (max-width: 320px)': { width: '280px' },
});

const GeneralMeetingDescription = ({ title, description }: GeneralMeetingDescriptionProps) => {
  const theme = useTheme();

  return (
    <Stack bgcolor={theme.palette.common.blue[50]} padding={5} spacing={2} borderRadius={2} alignItems="start">
      <Typography variant="h2" color={theme.palette.common.blue[500]} textAlign="start">
        {title}
      </Typography>
      <Typography variant="body2" color={theme.palette.grey[800]} textAlign="start">
        {description}
      </Typography>
    </Stack>
  );
};

const GeneralMeetingSectionDescription = () => (
  <Stack spacing={2} marginX={{ xs: 2, sm: 4, md: 0 }}>
    <GeneralMeetingDescription
      title="전자 등기 서비스"
      description="우체국 등기와 동일한 법적 효력을 가진 전자 등기 서비스"
    />
    <GeneralMeetingDescription
      title="전자 서면결의 서비스"
      description="본인 인증과 전자 서명을 통해 휴대폰에서 손쉽게 제출"
    />
    <GeneralMeetingDescription
      title="전자 투표 서비스"
      description="총회 현장에서 휴대폰, 태블릿 등의 단말기를 통해 투표 진행하여 시간과 비용 절감"
    />
  </Stack>
);

const GeneralMeeting = () => {
  const theme = useTheme();

  return (
    <Stack paddingY={10} alignItems="center" useFlexGap>
      <Typography variant="h2" color={theme.palette.primary.main}>
        총회
      </Typography>
      <Typography variant="title" marginTop={1} style={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}>
        전자적 방식의 총회 지원
      </Typography>
      <Typography
        variant="h3"
        marginTop={2}
        marginX={{ xs: 2, sm: 4, md: 0 }}
        style={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}
      >
        전자 총회 도입으로 번거로운 절차를 최소화하고, 비용과 인력을 절감하며, 조합원 참여를 더욱 촉진합니다.
      </Typography>
      <Stack direction={{ xs: 'column', lg: 'row' }} marginTop={5} spacing={5} useFlexGap>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <GeneralMeetingDescriptionImage src={MainPhoneGeneralMeeting} alt="" width="386px" />
        </Stack>
        <GeneralMeetingSectionDescription />
      </Stack>
    </Stack>
  );
};

export default memo(GeneralMeeting);
