import { Stack } from '@mui/material';
import { memo } from 'react';

import Authentication from './component/authentication';
import Community from './component/community';
import Footer from './component/footer';
import GeneralMeeting from './component/general-meeting';
import Header from './component/header';
import Intro from './component/intro';
import Library from './component/library';
import Outro from './component/outro';

const MainPage = () => (
  <Stack width="100%">
    <Header />
    <Intro />
    <GeneralMeeting />
    <Authentication />
    <Community />
    <Library />
    <Outro />
    <Footer />
  </Stack>
);

export default memo(MainPage);
