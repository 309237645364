import { AppBar, Button, Container, Stack, useTheme } from '@mui/material';
import { memo, useCallback } from 'react';
import AppIcon from '../../../assets/logo-header.svg';

const Header = () => {
  const theme = useTheme();

  const moveAdminPage = useCallback(() => {
    const adminUrl: string = 'https://admin.thezib.kr';
    window.location.href = adminUrl;
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        borderBottom: 1,
        borderBottomColor: theme.palette.grey[300],
      }}
    >
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" paddingY={2} paddingX={{ xs: 2, md: 17.5 }}>
          <img src={AppIcon} alt="" style={{ width: '62px' }} />
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            sx={{ bgcolor: theme.palette.common.white, color: theme.palette.primary.main }}
            onClick={moveAdminPage}
          >
            관리자 페이지
          </Button>
        </Stack>
      </Container>
    </AppBar>
  );
};

export default memo(Header);
