import { Stack, Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import FooterKEVLogo from '../../../assets/kevoting-footer.svg';

const Footer = () => {
  const theme = useTheme();

  return (
    <Stack
      bgcolor={theme.palette.grey[800]}
      alignItems="start"
      paddingLeft={{ xs: 2, sm: 4, md: 17.5 }}
      paddingY={{ xs: 5, sm: 8 }}
      spacing={3}
    >
      <img src={FooterKEVLogo} alt="" style={{ width: '148px', height: '30px' }} />
      <Stack alignItems="start" spacing={0.5}>
        <Typography variant="body3" color={theme.palette.common.white} sx={{ fontWeight: 700 }}>
          (주)한국전자투표
        </Typography>
        <Typography variant="body3" color={theme.palette.common.white}>
          <strong>주소</strong>&nbsp;서울특별시 영등포구 선유로 146 이앤씨드림타워 706호
        </Typography>
        <Typography variant="body3" color={theme.palette.common.white}>
          <strong>전화</strong>&nbsp;1899-1433
        </Typography>
        <Typography variant="body3" color={theme.palette.common.white}>
          <strong>고객문의</strong>&nbsp;thezib@kevoting.com
        </Typography>
      </Stack>
    </Stack>
  );
};

export default memo(Footer);
