const blue = {
  50: '#E4F1FF',
  100: '#CCE3FB',
  500: '#0073EA',
  600: '#0060B9',
  700: '#223465',
  800: '#000D3A',
};

const red = {
  100: '#FFE8E8',
  500: '#E72D2D',
  800: '#CD1515',
};

const yellow = {
  100: '#FFEFD2',
  300: '#FFC652',
  500: '#FFB319',
};

const green = {
  100: '#D2F9D8',
  300: '#64DC77',
  500: '#4AD15F',
};

export const colors = {
  blue,
  red,
  yellow,
  green,
};

export const grey = {
  50: '#FFFFFF',
  100: '#F5F5F5',
  200: '#EFEFEF',
  300: '#DFDFDF',
  400: '#C8C8C8',
  500: '#B7B7B7',
  600: '#949494',
  700: '#777777',
  800: '#3D3D3D',
  900: '#111111',
};
