import { Button, Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import AppStoreIcon from '../../../assets/app-store-icon.svg';
import PlayStoreIcon from '../../../assets/play-store-icon.svg';

interface StoreButtonProps {
  icon: StoreEnum;
  label: string;
  onClick: () => void;
}

export enum StoreEnum {
  AppStore,
  PlayStore,
}

const StoreIcon = {
  [StoreEnum.AppStore]: AppStoreIcon,
  [StoreEnum.PlayStore]: PlayStoreIcon,
};

const StoreButton = ({ icon, label, onClick }: StoreButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      sx={{
        width: '180px',
        backgroundColor: theme.palette.grey[800],
        padding: '16px',
        ':hover': { backgroundColor: theme.palette.grey[800], opacity: 0.85 },
      }}
      onClick={onClick}
    >
      <img src={StoreIcon[icon]} alt="" style={{ height: '20px' }} />
      <Typography variant="h3" color={theme.palette.common.white} marginLeft={1} textTransform="none">
        {label}
      </Typography>
    </Button>
  );
};

export default memo(StoreButton);
