import { Container, Stack, Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import MainPhoneCommunity from '../../../assets/community-description.png';

const Community = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="xl">
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        paddingX={{ xs: 2, sm: 4, md: 17.5 }}
        paddingY={{ xs: 5, sm: 10 }}
        justifyContent="space-between"
        useFlexGap
      >
        <Stack spacing={1} useFlexGap marginBottom={{ xs: 5, lg: 0 }} flex={1}>
          <Typography variant="h2" color={theme.palette.primary.main} textAlign="start">
            커뮤니티
          </Typography>
          <Typography variant="title" textAlign="start">
            안전한 소통 공간
          </Typography>
          <Typography
            variant="h3"
            textAlign="start"
            marginTop={1}
            lineHeight="26px"
            style={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}
          >
            인증된 구성원만을 위한 효율적인 소통과 간편한 관리
          </Typography>
        </Stack>
        <Stack maxWidth={{ xs: '90%', lg: '100%' }} alignSelf="center" flex={1}>
          <img src={MainPhoneCommunity} alt="" width="100%" />
        </Stack>
      </Stack>
    </Container>
  );
};

export default memo(Community);
