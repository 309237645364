import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AppDownload from '../views/app-download';
import Main from '../views/main';

function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/*" element={<AppDownload />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigation;
