import { Container, Stack, Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import MainPhoneLibrary from '../../../assets/library-description.png';

const Library = () => {
  const theme = useTheme();

  return (
    <Stack bgcolor={theme.palette.secondary.light}>
      <Container maxWidth="xl">
        <Stack
          direction={{ xs: 'column-reverse', lg: 'row' }}
          paddingX={{ xs: 2, sm: 4, md: 17.5 }}
          paddingY={{ xs: 5, sm: 10 }}
          columnGap={20}
          justifyContent="space-between"
          useFlexGap
        >
          <Stack maxWidth={{ xs: '70%', lg: '100%' }} flex={1} alignSelf="center">
            <img src={MainPhoneLibrary} alt="" width="100%" />
          </Stack>

          <Stack useFlexGap marginBottom={{ xs: 5, lg: 0 }} flex={1} rowGap={2}>
            <Typography variant="title" textAlign="start">
              자료실
            </Typography>
            <Typography
              variant="h3"
              textAlign="start"
              lineHeight="1.3"
              style={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}
            >
              정비사업 추진사항에 관한 공개 자료를 구성원에게 간편하고 투명하게 제공
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default memo(Library);
