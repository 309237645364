import { Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';
import BgImage from '../../assets/mobile-download-background.png';
import AppIcon from '../../assets/logo-mobile-download.svg';

const AppDownload = () => {
  const theme = useTheme();
  const [isRunApplicationButtonLoading, setIsRunApplicationButtonLoading] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const moveToStore = useCallback(() => {
    const playStoreUrl: string = 'https://play.google.com/store/apps/details?id=kr.thezib';
    const appStoreUrl: string = 'https://apps.apple.com/us/app/%EB%8D%94%EC%A7%91/id6466390562';
    const appleCorporationOSList: string[] = ['iOS', 'Mac OS', 'watchOS', 'tvOS'];
    const parser = new UAParser();
    const deviceOSName = parser.getOS().name;
    const isAppleOS: boolean = appleCorporationOSList.some((appleOS) => appleOS === deviceOSName);
    window.location.href = isAppleOS ? appStoreUrl : playStoreUrl;
  }, []);

  const runApplication = useCallback(() => {
    if (isRunApplicationButtonLoading) {
      return;
    }

    const userAccessUrl: string = window.location.href;
    const accessDomainList = [process.env.REACT_APP_ACCESS_URL, process.env.REACT_APP_ACCESS_SECURE_PROTOCOL_URL];
    const containAccessDomain = accessDomainList.find(
      (accessDomain) => accessDomain && userAccessUrl.startsWith(accessDomain),
    );
    const appScheme = process.env.REACT_APP_SCHEME;

    if (containAccessDomain && appScheme) {
      let schemeReplacedUrl = userAccessUrl.replace(containAccessDomain, appScheme);
      schemeReplacedUrl = schemeReplacedUrl.replace(/\/{3,}/g, '//');
      window.location.href = schemeReplacedUrl;
    }

    setIsRunApplicationButtonLoading(true);
    setTimeout(() => {
      setIsRunApplicationButtonLoading(false);
      if (isFocused) {
        moveToStore();
      }
    }, 1000);
  }, [isFocused, isRunApplicationButtonLoading, moveToStore]);

  useEffect(() => {
    const windowFocusHandler = () => {
      window.addEventListener('focus', onFocus);
      window.addEventListener('blur', onBlur);
    };

    windowFocusHandler();

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [onBlur, onFocus]);

  return (
    <Stack
      width="100%"
      minHeight="100vh"
      bgcolor={theme.palette.primary.main}
      style={{
        backgroundImage: `url(${BgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Stack spacing={5}>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body1" color={theme.palette.common.white}>
            재건축•재개발을
          </Typography>
          <Typography variant="body1" color={theme.palette.common.white} style={{ fontWeight: 800 }}>
            더
          </Typography>
          <Typography variant="body1" color={theme.palette.common.white}>
            쉽게
          </Typography>
        </Stack>
        <img src={AppIcon} alt="" style={{ marginTop: '40px' }} />
      </Stack>
      <Stack spacing={2}>
        <Typography variant="subtitle3" color={theme.palette.common.white} textAlign="center">
          앱이 실행되지 않으면 아래 버튼을 이용하세요.
        </Typography>
        <Stack width="100%" spacing={2} direction="row" justifyContent="center" marginLeft={2} marginRight={2}>
          <Button
            variant="contained"
            size="large"
            color="inherit"
            sx={{ bgcolor: theme.palette.common.white, color: theme.palette.primary.main, height: 56 }}
            onClick={runApplication}
          >
            {isRunApplicationButtonLoading ? <CircularProgress /> : '앱 실행하기'}
          </Button>
          <Button
            variant="contained"
            size="large"
            color="inherit"
            sx={{ bgcolor: theme.palette.common.white, color: theme.palette.primary.main, height: 56 }}
            onClick={moveToStore}
          >
            설치하러 가기
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AppDownload;
