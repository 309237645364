import { Stack, Typography, useTheme } from '@mui/material';
import { memo, useCallback } from 'react';
import StoreButton, { StoreEnum } from './storeButton';

const Outro = () => {
  const theme = useTheme();

  const moveToAppStore = useCallback(() => {
    const appStoreUrl: string = 'https://apps.apple.com/us/app/%EB%8D%94%EC%A7%91/id6466390562';
    window.location.href = appStoreUrl;
  }, []);

  const moveToPlayStore = useCallback(() => {
    const playStoreUrl: string = 'https://play.google.com/store/apps/details?id=kr.thezib';
    window.location.href = playStoreUrl;
  }, []);

  return (
    <Stack
      bgcolor={theme.palette.common.blue[500]}
      spacing={{ xs: 3, sm: 5 }}
      paddingTop={{ xs: 5, sm: 10 }}
      paddingBottom={{ xs: 5, sm: 6.5 }}
      alignItems="center"
      useFlexGap
    >
      <Typography
        variant="h3"
        color={theme.palette.common.white}
        textAlign="center"
        marginX={{ xs: 2, sm: 4, md: 4, lg: 0 }}
        style={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}
      >
        더집은 재개발•재건축 사업을 빠른 성공으로 이끄는 가장 믿을 수 있는 지원 서비스입니다.
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }} spacing={2}>
        <StoreButton icon={StoreEnum.AppStore} label="App Store" onClick={moveToAppStore} />
        <StoreButton icon={StoreEnum.PlayStore} label="Google Play" onClick={moveToPlayStore} />
      </Stack>
    </Stack>
  );
};

export default memo(Outro);
