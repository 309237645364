import { Container, Divider, Stack, Typography, useTheme } from '@mui/material';
import { memo } from 'react';
import MainPhoneJoin from '../../../assets/authentication-description.png';

const Authentication = () => {
  const theme = useTheme();

  return (
    <Stack bgcolor={theme.palette.secondary.light}>
      <Container maxWidth="xl">
        <Stack
          direction={{ xs: 'column-reverse', lg: 'row' }}
          paddingX={{ xs: 2, sm: 4, md: 17.5 }}
          paddingY={{ xs: 5, sm: 10 }}
          columnGap={20}
          justifyContent="space-between"
          useFlexGap
        >
          <Stack maxWidth={{ xs: '80%', lg: '100%' }} alignSelf="center" flex={1}>
            <img src={MainPhoneJoin} alt="" width="100%" />
          </Stack>
          <Stack spacing={1} useFlexGap marginBottom={{ xs: 5, lg: 0 }} flex={1}>
            <Typography variant="h2" color={theme.palette.primary.main} textAlign="start">
              회원가입
            </Typography>
            <Typography variant="title" textAlign="start">
              간편한 가입 절차
            </Typography>
            <Typography
              variant="h3"
              textAlign="start"
              marginY={1}
              lineHeight="26px"
              style={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}
            >
              본인 인증으로 빠르고 쉽게 사업장 커뮤니티에 가입
            </Typography>
            <Stack>
              <Stack
                alignItems="start"
                justifyContent="space-between"
                spacing={0}
                height="96px"
                px={2}
                ml="1.5px"
                borderLeft={`1px solid ${theme.palette.grey[600]}`}
              >
                <Typography variant="subtitle2" color={theme.palette.grey[600]}>
                  회원 가입
                </Typography>
                <Typography variant="subtitle2" color={theme.palette.grey[600]}>
                  본인 인증
                </Typography>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  커뮤니티 가입 완료
                </Typography>
              </Stack>
              <Divider
                style={{
                  backgroundColor: theme.palette.primary.main,
                  height: '32px',
                  width: '3px',
                  position: 'absolute',
                  marginTop: '64px',
                }}
                orientation="vertical"
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default memo(Authentication);
