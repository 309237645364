import { Stack, Typography, useTheme } from '@mui/material';
import { memo, useCallback } from 'react';
import BgIntro from '../../../assets/intro-background.png';
import MainPhoneLogo from '../../../assets/intro-description.png';
import StoreButton, { StoreEnum } from './storeButton';

const Intro = () => {
  const theme = useTheme();

  const moveToAppStore = useCallback(() => {
    const appStoreUrl: string = 'https://apps.apple.com/us/app/%EB%8D%94%EC%A7%91/id6466390562';
    window.location.href = appStoreUrl;
  }, []);

  const moveToPlayStore = useCallback(() => {
    const playStoreUrl: string = 'https://play.google.com/store/apps/details?id=kr.thezib';
    window.location.href = playStoreUrl;
  }, []);

  return (
    <Stack
      bgcolor={theme.palette.primary.main}
      style={{
        backgroundImage: `url(${BgIntro})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent="center"
      marginTop={9}
      paddingTop={10}
      spacing={{ xs: 6, lg: 10 }}
      useFlexGap
    >
      <Stack spacing={{ xs: 5, lg: 3 }} marginX={{ xs: 2, lg: 0 }} paddingTop={{ xs: 15, lg: 13.5 }}>
        <Typography
          variant="title"
          color={theme.palette.common.white}
          textAlign={{ xs: 'center', lg: 'start' }}
          style={{ wordBreak: 'keep-all', wordWrap: 'break-word' }}
        >
          정비사업을 처음부터 끝까지 함께 하는 최고의 파트너
        </Typography>
        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2} alignItems="center">
          <StoreButton icon={StoreEnum.AppStore} label="App Store" onClick={moveToAppStore} />
          <StoreButton icon={StoreEnum.PlayStore} label="Google Play" onClick={moveToPlayStore} />
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Stack maxWidth={{ xs: '50%', lg: '423px' }}>
          <img src={MainPhoneLogo} alt="" width="100%" />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(Intro);
